.header{
  
  background: rgba(0, 0, 0, 0.512);
  justify-content: start;
  
  width: 100%;
}
.header a {
  text-decoration: none;
  font-family: noto-sans, sans-serif;
  margin-right: 25px;
  padding: 4px 5px;
  color: rgb(255, 255, 255);
  font-weight: 400;
  font-size: 14px;
 line-height: 22px;
 letter-spacing: .15px;
  /* padding: 0 100px; */

}

/* navbar transparent color */
.navbar {
  /* background: rgba(0, 0, 0, 0.512); */
  border: none;
  border-radius: 0;
  margin-bottom: 0;
  font-size: 12px;
  letter-spacing: 5px;
  opacity: 0.9;
}

.header a:hover {
  color: #F5D061;

}
.header a.active {
  /* background-color: #000; */
  color: #F5D061;
}