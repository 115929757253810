body{
    background-image: url(./images/csify_background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    margin: 0;
    padding: 0;
 
}
.container{
    margin: auto;
    overflow: hidden;
    
}